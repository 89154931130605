/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/termn',
    name: 'termn',
    component: () => import('@/views/termn.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/police',
    name: 'police',
    component: () => import('@/views/privacyPolicy.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/e-form/survey/:id',
    name: 'e-form-survey',
    component: () => import('@/views/e-form/survey-noparams.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/e-form/survey/:id/:phone/:name',
    name: 'e-form-survey',
    component: () => import('@/views/e-form/Survey.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/order/:name',
    name: 'order',
    component: () => import('@/views/Order/OrderPage.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/order-detail/:name/:id',
    name: 'order-detail',
    component: () => import('@/views/Order/OrderDetail.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/checkout/:name',
    name: 'order-checkout',
    component: () => import('@/views/Order/OrderCheckOut.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/err',
    name: 'order-checkout',
    component: () => import('@/views/fb.vue'),
    meta: {
      layout: 'blank',
    },
  },

  // {
  //   path: '*',
  //   redirect: 'Error-404',
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
