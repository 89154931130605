export default {
  chartStatistic: [],
  shopList: [],
  statAgregate: {},
  products: [],
  provinces: [],
  cities: [],
  cost: [],
  localCouriers: [],
  fbPixelSave: null,
  currentConvertion: 0,
  saveProductToVueX: null,

  // subDistrict: [],
}
