import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
// import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import eOrdering from './e-ordering'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    eOrdering,
  },
  plugins: [createPersistedState()],
})

// export default function (/* { ssrContext } */) {
//   const Store = new Vuex.Store({
//     state: {},
//     mutations: {},
//     actions: {},
//     modules: {
//       appConfig: appConfigStoreModule,
//       app,
//       auth,
//     },

//     // enable strict mode (adds overhead!)
//     // for dev mode only
//     strict: process.env.DEBUGGING,
//     plugins: [createPersistedState()],
//   })

//   return Store
// }
