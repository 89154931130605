export function setChartStatistic(state, obj) {
  state.chartStatistic = obj
}

export function setShopList(state, obj) {
  state.shopList = obj
}

export function setStatAgregate(state, obj) {
  state.statAgregate = obj
}

export function setProducts(state, obj) {
  state.products = obj
}

export function setProvince(state, obj) {
  state.provinces = obj
}

export function setCurrentConvertion(state, obj) {
  state.currentConvertion = obj
}

export function setCity(state, obj) {
  state.cities = obj
}

export function setCost(state, obj) {
  state.cost = obj
}

export function setLocalCouriers(state, obj) {
  state.localCouriers = obj
}
export function setFbPixelSave(state, obj) {
  state.fbPixelSave = obj
}
export function setSaveProductToVueX(state, obj) {
  state.saveProductToVueX = obj
}

// export function setSubDistrict(state, obj) {
//   state.subDistrict = obj
// }saveProductToVueX
