export function getChartStatistic(state) {
  return state.chartStatistic
}
export function getStatAgregate(state) {
  return state.statAgregate
}
export function getShopList(state) {
  return state.shopList
}
export function getProducts(state) {
  return state.products
}
export function getProvince(state) {
  return state.provinces
}
export function getCity(state) {
  return state.cities
}
export function getCost(state) {
  return state.cost
}
export function getCurrentConvertion(state) {
  return state.currentConvertion
}
export function getLocalCouriers(state) {
  return state.localCouriers
}
export function getFbPixelSave(state) {
  return state.fbPixelSave
}
export function getsaveProductToVueX(state) {
  return state.saveProductToVueX
}

// export function getSubDistrct(state) {
//   return state.subDistrict
// }saveProductToVueX
